import React, { Component } from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { graphql } from "gatsby"
import get from "lodash/get"
import ArticleContent from "../components/article/ArticleContent"
import GetStartedBlock from "../components/core/GetStartedBlock"
import { Link } from "gatsby"
import { Container, Row, Col } from "styled-bootstrap-grid"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import BackgroundImage from "gatsby-background-image"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import Overlay from "../components/core/Overlay"
import linkResolver from "../utils/linkResolver";

const BackgroundStyled = styled.div`
    position: relative;
    height: 745px;
    margin-top: 91px;
    @media(min-width: ${breakpoints.lg}){
        height: ${props => props.extraLarge ? "700px" : props => props.large ? "600px": "500px" };
        margin-top: 98px;
    }
`
const BackgroundImageStyled = styled(BackgroundImage)`
    width: 100vw;
    min-height: 100%;
    background-size: cover;
    z-index: -4;
`

const RowStyled = styled(Row)`
    margin: 0 33px 0 32px;
`

const ServiceTitle = styled.h1`
    color: ${colors.white};
    ${fonts.jaggerRegular};
    border-left: 2px solid ${colors.blue};
    font-size: 42px;
    letter-spacing: -1.35px;
    line-height: 52px;
    max-width: 232px;
    padding-left: 14px;
    @media(min-width: ${breakpoints.md}){
        font-size: 62px;
        letter-spacing: -2px;
        line-height: 72px;
        max-width: 486px;
        padding-left: 18px;
    }
`

const ServiceDescription = styled.div`
    color: ${colors.white};
    ${fonts.dmSansRegular};
    font-size: 15px;
    line-height: 23px;
    max-width: 432px;
    margin-top: 40px;
`

const HeroBox = styled.div`
    margin-right: 33px;
    margin-left: 33px;
    padding-bottom: 85px;
    position: absolute;
    bottom: 0;
    z-index: 2;
    @media(min-width: ${breakpoints.lg}){
        padding-bottom: 115px;
        margin-left: 34px;
    }
`

const SidebarTitle = styled.h3`
    ${fonts.dmSansBold};
    font-size: 11px;
    letter-spacing: 1.5px;
    color: ${colors.grey};
    text-transform: uppercase;
    margin-bottom: 29px;
`

const StyledLink = styled(Link)`
    cursor: default;
    ${fonts.dmSansMedium};
    font-size: 20px;
    color: ${colors.darkBlue};
    letter-spacing: 0;
    line-height: 45px;
    text-decoration: none;
    
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
    
    &:hover {
        text-decoration: none;
    }
    
    img {
        margin-right: 15px;
    }
`

const StyledText = styled.div`
    cursor: pointer;
    display: flex;
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
        color: ${colors.green};
    }
    &:hover {
        text-decoration: underline;
        color: ${colors.green};
    }
`

const SidebarBox = styled.div`
    margin-bottom: 47px;
`

const ColStyled = styled(Col)`
  padding-top: 90px;
  padding-bottom: 90px;
  background: ${props => props.blue ? colors.lightBlue : colors.white};
  padding-left: ${props => props.blue ? '50px' : null};
  padding-right: ${props => props.blue ? '50px' : null};
  box-sizing: border-box;
  @media(min-width: ${breakpoints.lg}){
      padding-top: 130px;
      padding-bottom: 150px;
      padding-left: 0;
  }
`

const ServiceContainer = styled(Container)`
  position: relative;
`;

const ServiceContainerBackground = styled.div`
    position: absolute;
    width: 28%;
    height: 100%;
    background: ${colors.lightBlue};
    display: none;
    @media(min-width: ${breakpoints.lg}){
      display: block;
    }
`;

const ServiceContainerBackgroundMobile = styled.div`
    position: absolute;
    width: 100%;
    height: 648px;
    background: ${colors.lightBlue};
    display: block;
    bottom: 0;
    z-index: -1;
    @media(max-width: 349px){
        height: 693px;
    }
    @media(max-width: 339px){
        height: 738px;
    }
    @media(min-width: ${breakpoints.lg}){
      display: none;
    }
`

class ServiceTemplate extends Component {
  render() {
    const path = this.props.location.pathname
    let service = get(this, "props.data.prismic.service.edges").slice(0, 1).pop()
    let services = get(this, "props.data.prismic.services.edges")

    if (!service) return null

    service = service.node

    let categories = []
    let serviceLinks = {}

    services.map((serviceData, index) => {
      const node = serviceData.node
      const {name : category, order} = node.category;

      if (!serviceLinks[category]) {
        serviceLinks[category] = []

        categories.push({category, order})
      }

      serviceLinks[category].push(node)
    })

    categories.sort((a, b) => a.order - b.order);

    return (
      <Layout meta={service._meta}>
        <SEO title={service.title}
             description={service.description}
             path={path}/>

        <BackgroundStyled>
          <Overlay/>
          {service.hero_imageSharp &&
          <BackgroundImageStyled fluid={service.hero_imageSharp.childImageSharp.fluid} alt={service.title}/>
          }
          <Container>
            <HeroBox>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </HeroBox>
          </Container>
        </BackgroundStyled>

        <ServiceContainer fluid>
          <ServiceContainerBackground/>
          <Container>
            <RowStyled>
              <ColStyled lg={7} lgOrder={2} lgOffset={2}>
                <ArticleContent article={service}/>
              </ColStyled>
              <ColStyled lg={3} lgOrder={1} blue>
                {categories.map(({category}, index) => {
                  return (
                    <SidebarBox key={index}>
                      <SidebarTitle>{category}</SidebarTitle>
                      {serviceLinks[category].map((serviceData, index) => {
                        return (
                          <StyledLink key={index} to={linkResolver(serviceData._meta)}>
                            <StyledText>
                            {serviceData.icon && <img src={serviceData.icon.url} alt={serviceData.title}/>}
                            {serviceData.title}
                            </StyledText>
                          </StyledLink>
                        )
                      })}
                    </SidebarBox>
                  )
                })}
              </ColStyled>
            </RowStyled>
          </Container>
          <ServiceContainerBackgroundMobile/>
        </ServiceContainer>
        <GetStartedBlock/>
      </Layout>
    )
  }
}

export default ServiceTemplate

export const query = graphql`
    query ServiceQuery($lang: String, $uid: String) {
        prismic {
            services: allServices(lang: $lang, sortBy: position_ASC) {
                edges {
                    node {
                        _meta {
                          uid
                          lang
                          type
                          alternateLanguages {
                            lang
                            type
                            uid
                          }
                        }
                        title
                        icon
                        category {
                            ... on PRISMIC_Service_category {
                                name
                                order
                            }
                        }
                    }
                }
            }
            service: allServices(lang: $lang, uid: $uid) {
                edges {
                    node {
                      _meta {
                        uid
                        lang
                        type
                        alternateLanguages {
                          lang
                          type
                          uid
                        }
                      }
                        title
                        description
                        hero_image
                        hero_imageSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                        body {
                            __typename
                            ... on PRISMIC_ServiceBodyBlock_text {
                                type
                                primary {
                                    content
                                }
                            }
                            ... on PRISMIC_ServiceBodyBlock_carousel {
                                type
                                fields {
                                    image
                                    imageSharp {
                                        childImageSharp {
                                            fluid(quality: 100, maxWidth: 1920, maxHeight: 930) {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                    }
                                }
                            }
                            ... on PRISMIC_ServiceBodyBlock_text_image {
                                type
                                primary {
                                    content
                                    image
                                    imageSharp {
                                        childImageSharp {
                                            fluid(quality: 100, maxWidth: 720, maxHeight: 720) {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                    }
                                    image_position
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
