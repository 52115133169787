import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import styled from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import LeftArrow from "../../resources/img/home/left-arrow.svg"
import RightArrow from "../../resources/img/home/right-arrow.svg"

const Slide = styled.div`
    outline: none;
    border: 0;
    max-height: 288px;
    @media(min-width: ${breakpoints.lg}){
        height: 415px;
    }
`

const ControllerContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: -1px;
    @media(max-width: 320px){
        bottom: -3px;
        right: -2px;
    }
`

const CarouselController = styled.img`
    cursor: pointer;
    border: none;
    width: 16px;
    height: 16px;
    padding: 24px;
`

const Previous = styled.div`
    cursor: pointer;
    background-color: ${colors.white};
    height: 63px;
    width: 63px;
    display: inline-block;
`

const Next = styled.div`
    cursor: pointer;
    background-color: ${colors.white};
    height: 63px;
    width: 63px;
    display: inline-block;
    margin-left: 3px;
`

const CarouselContainer = styled.div`
    position: relative;
    @media(min-width: ${breakpoints.lg}){
        margin-top: 80px;
    }
`


class BlockCarousel extends React.Component {
  state = {
    slideIndex: 0,
  }

  changeSlide(diff) {
    const slideIndex = this.state.slideIndex + diff

    this.slider.slickGoTo(slideIndex)
  }

  render() {
    const { block } = this.props
    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      fade: false,
      swipe: true,
      swipeToSlide: true,
      draggable: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
      initialSlide: 0,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    }
    return (
      <CarouselContainer>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {block.fields.map((field, index) => {
            return (
              <Slide key={index}>
                {field.imageSharp && <Img fluid={field.imageSharp.childImageSharp.fluid} alt={field.image.alt}/>}
              </Slide>
            )
          })}
        </Slider>
        {block.fields.length > 1 && (
          <ControllerContainer>
            <Previous onClick={() => this.changeSlide(-1)}><CarouselController src={LeftArrow}/></Previous>
            <Next onClick={() => this.changeSlide(1)}><CarouselController src={RightArrow}/></Next>
          </ControllerContainer>
        )}
      </CarouselContainer>
    )
  }
}

export default BlockCarousel
