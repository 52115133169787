import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"

const VideoBlock = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  
  > div {
    margin: auto;
    
    width: 100%;
    height: 400px;
  }
  
  iframe {
    width: 100%;
    height: 100%;
  }
  
  @media(min-width: ${breakpoints.md}){
    margin-bottom: 48px;
  }
`

const BlockVideo = ({ block }) => {
  const video = block.primary.video;

  return (
    <VideoBlock>
      <div dangerouslySetInnerHTML={{ __html: video.html }} />
    </VideoBlock>
  )
}

export default BlockVideo;



