import React, { Component } from "react"
import BlockText from "./BlockText"
import BlockTextImage from "./BlockTextImage"
import BlockCarousel from "./BlockCarousel"
import BlockVideo from "./BlockVideo"
import styled from "styled-components"

const ArticleContainer = styled.div`
  width: 100%;
  max-width: 648px;
  margin: auto;
`;

class ArticleContent extends Component {
  render() {
    const { article } = this.props

    let articleContent = []

    const blocks = article.body

    blocks.forEach((block, index) => {
      const blockType = block.type

      if (blockType === "block_text" || blockType === "content") {
        articleContent.push(<BlockText key={index} block={block}/>)
      }

      if (blockType === "block_text_image") {
        articleContent.push(<BlockTextImage key={index} block={block}/>)
      }

      if (blockType === "block_carousel") {
        articleContent.push(<BlockCarousel key={index} block={block}/>)
      }

      if (blockType === "block_video") {
        articleContent.push(<BlockVideo key={index} block={block}/>)
      }
    })

    return (
      <ArticleContainer>
        {articleContent}
      </ArticleContainer>
    )
  }
}

export default ArticleContent
