import React from "react"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import ListHexagon from "../../resources/img/icons/list-hexagon.svg"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"

const ArticleStyled = styled.div`
  text-align: justify;
  p {
    ${fonts.dmSansRegular};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 25px;
  }
  
  b, strong {
    ${fonts.dmSansBold};
  }
  
  em {
    font-style: italic;
  }
  
  a {
    color: ${colors.darkBlue};
  }

  h2, h3, h4 {
    text-align: left;
  }
    
  h2 {
    font-size: 28px;
    margin-bottom: 27px;
    letter-spacing: -0.8px;
    line-height: 38px;
  }
  
  h3 {
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 23px;
  }
  
  ul {
    list-style-image: url(${ListHexagon});
    list-style-position: inside;
  }
  
  li {
    font-size: 15px;
  }
  
  max-width: 648px;  
`
const BlockText = ({ block }) => {
  return (
    <ArticleStyled>
      {RichText.render(block.primary.content)}
    </ArticleStyled>
  )
}

export default BlockText;
