import React from "react"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import BlueLineImg from '../../resources/img/icons/blue-line.svg'
import ListHexagon from "../../resources/img/icons/list-hexagon.svg"
import colors from "../../styles/colors"

const BlockTextImageStyled = styled.div`
    margin-top: 60px;
    margin-bottom: 24px;
    
    @media(min-width: ${breakpoints.lg}){
        margin-top: 80px;
        margin-bottom: 48px;
    }
    
    p {
      ${fonts.dmSansRegular};
      font-size: 15px;
      letter-spacing: 0;
      line-height: 23px;
      margin-bottom: 25px;
  }
`

const BlockImage = styled(Img)`
    margin-bottom: 80px;
    @media(min-width: ${breakpoints.lg}){
        margin-top: 0;
    }
`

const BlockText = styled.div`
  text-align: justify;
    p {
      ${fonts.dmSansRegular};
      font-size: 15px;
      letter-spacing: 0;
      line-height: 23px;
      margin-bottom: 25px;
    }
    
    b, strong {
      ${fonts.dmSansBold};
    }
    
    em {
      font-style: italic;
    }
    
    a {
      color: ${colors.darkBlue};
    }
    
    h2, h3, h4 {
      text-align: left;
    }
    
    h2 {
      font-size: 28px;
      margin-bottom: 27px;
      letter-spacing: -0.8px;
      line-height: 38px;
    }
    
    h3 {
      font-size: 18px;
      margin-bottom: 25px;
      line-height: 23px;
    }
    
    ul {
      list-style-image: url(${ListHexagon});
      list-style-position: inside;
    }
    
    li {
      font-size: 15px;
    }
    max-width: 648px;

    @media(min-width: ${breakpoints.lg}){
      // margin-top: 10px;
      margin-left: ${props => props.right ? "0" : "30px"};
      margin-right: ${props => props.right ? "30px" : "0"};      
    }
    @media(min-width: ${breakpoints.xl}){
      // margin-top: 42px;
      margin-left: ${props => props.right ? "0" : "72px"};   
      margin-right: ${props => props.right ? "72px" : "0"};   
    }
    
    &:after {
        content: url(${BlueLineImg});
        display: none;
        @media(min-width: ${breakpoints.lg}){
            display: block;
        }
    }
`


const BlockTextImage = ({ block }) => {
  const imagePosition = block.primary.image_position;
  const isRight = imagePosition === "Right";

  return (
    <BlockTextImageStyled>
      <Row>
        <Col lg={6} order={isRight ? 2 : 1}>
          <BlockImage fluid={block.primary.imageSharp.childImageSharp.fluid} alt={block.primary.image.alt}/>
        </Col>
        <Col lg={6} order={isRight ? 1 : 2}>
          <BlockText right={isRight}>{RichText.render(block.primary.content)}</BlockText>
        </Col>
      </Row>
    </BlockTextImageStyled>
  )
}

export default BlockTextImage
